.reports-table {
  .items-container {
    margin-top:17px;
    display: flex;
    row-gap: 80px;
    column-gap: 40px;

    flex-wrap: wrap;
    .item {
      width: 270px;
      border: 0;

      &__header {
        padding: 15px 25px;
        box-shadow: 0 10px 26px 0 rgba(0, 0, 0, 0.19);
        background-color: #fff;
        margin-bottom: 30px;

        h5 {
          padding: 0;
          margin: 0;
          text-transform: uppercase;
          font-size: 11px;
        }

        h1 {
          margin: 0;
          font-size: 28px;
          line-height: 32px;
          letter-spacing: -1px;
          padding: 10px 0;
        }
      }

      &__content {
        font-size: 14px;
        line-height: 20px;
        margin-bottom: 30px;
      }

      &__footer {
      }
    }
  }
}